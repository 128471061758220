<template>
  <div>
    <!-- 新增提示 -->		
						<div class="remind" v-if="!message.IsOpen" >
							<span style="margin-left:16px">订阅通知，当短信账户剩余数量不足时，我们将通过短信给你发送提醒。</span>
						  <span class="co" @click="handclick">前往订阅</span>						
						</div> 
    <el-card v-loading="numloading">
      <div style="color:#666666;">短信剩余（条）
        <el-link href="https://jusnn6k8al.feishu.cn/docs/doccnu97Kv6jgF8OwsSloGG0Oqg" target="_blank" :underline="false">
          <i class = "el-icon-warning-outline" style="font-size:16px"></i>
        </el-link>
      </div>
      <div class = "flexRow" style="padding-top:15px">
        <div class = "smsnumfont">{{agreement.SMSCount}}</div>
        <el-button type="text" style="margin-left:50px" @click="topup">充值</el-button>
        <span class="pay-help" style="margin-left:30px" @click="serviceshow=true;servicefont='充值过程中，若遇到疑问，使用微信扫描下方二维码，'">
          <i class="el-icon-warning" style="font-size: 14px;color: #E6A23C;"></i>
          <span>支付成功了确一直没到账？</span>
        </span>
        <!-- <el-link  target="_blank" type="info" @click="serviceshow=true;servicefont='充值过程中，若遇到疑问，使用微信扫描下方二维码，'"
         style="margin-left:30px">
         <i class = "el-icon-warning" style="font-size:16px;color:#E6A23C"></i>支付成功了，短信剩余数量没有增加？
        </el-link> -->
      </div>
    </el-card>
    <el-card style="margin-top:10px">
      <div class = "flexRow" style="flex-wrap:wrap">
        <div class = "flexRow">
          <div class = "keyfont">变更原因：</div>
          <el-select v-model="reason" style="width:200px">
            <el-option v-for="(v,i) in reasonlist" :key="i" :value="v.value" :label="v.label"></el-option>
          </el-select>
        </div>
        <div class = "flexRow" style="margin:0px 10px">
          <div class = "keyfont">变更时间：</div>
          <el-date-picker v-model="starttime" type="datetime" placeholder="开始时间"
           value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
          <span style="margin:0px 10px">~</span>
          <el-date-picker v-model="endtime" type="datetime" placeholder="开始时间"
            value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
        </div>
        <el-button type="primary" @click="query">查询</el-button>
      </div>
      <div style="margin-top:10px">
        <el-table :data="tablelist" v-loading="tableloading">
          <el-table-column prop="RecordTypeValue" label="变更原因"></el-table-column>
          <el-table-column prop="ChangeCount" label="变更数量">
            <template slot-scope="scope">
              <span v-if="scope.row.ChangeCount>=0">
                {{scope.row.ChangeCount}}
              </span>
              <span v-else style="color:#f56c6c">
               {{scope.row.ChangeCount}}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="AddTime" label="变更时间"></el-table-column>
          <el-table-column prop="Remark" label="备注">
            <template slot-scope="scope">
              <span>
                {{scope.row.Remark}}
              </span>
              <el-button type="text" v-if="scope.row.IsShowDetailButton" @click="getdetail(scope.row)">
                详情
              </el-button>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="" label="操作" width="200px">
            <template slot-scope="scope">
              <el-button type="text" v-if="scope.row.IsShowDetailButton" @click="getdetail(scope.row)">
                详情
              </el-button>
            </template>
          </el-table-column> -->
        </el-table>
      </div>
      <div style="margin-top:10px;text-align:right" v-if="total">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="sizepage"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </el-card>

    <!-- 客服 -->
    <el-dialog title="提示" :visible.sync="serviceshow" width="480px">
      <div style="line-height:18px">
        <i class = "el-icon-warning" style="font-size:16px;color:#E6A23C"></i>
        {{servicefont}}<span style="color:#E6A23C">添加专属客服</span>，一对一为您解答疑惑
      </div>
      <div style="width:100%;text-align:center">
        <img src="@/assets/img/20210719171848.jpg" style="width:300px;margin-top:20px" alt="">
      </div>
      <div style="margin-top:20px;text-align:right">
        <el-button size="mini" @click="serviceshow = false">我知道了</el-button>
      </div>
    </el-dialog>
    <!-- 详情 -->
    <el-dialog :visible.sync="detailshow" title="短信发送详情">
      <div class=  "detailtitle">
        短信发送内容
        <span>本条短信内容超出{{thenum.length}}字，每个手机号收到{{thenum.num}}条短信</span>
      </div>
      <div class = "graybox">
        {{detailmsg.Content}}
      </div>
      <div class = "detailtitle">短信发送结果</div>
      <div class = "flexRow" style="margin-top:15px">
        <div class=  'flexCol'>
          <div class = "numfont">{{detailmsg.SendingCount}}</div>
          <div class = "detailtitle">发送中手机号</div>
        </div>
        <div class=  'flexCol'>
          <div class = "numfont">{{detailmsg.SendSuccessCount}}</div>
          <div class = "detailtitle">发送成功手机号</div>
        </div>
        <div class=  'flexCol'>
          <div class = "numfont">{{detailmsg.SendFailCount}}</div>
          <div class = "detailtitle">发送失败手机号
            <el-tooltip class="item" effect="dark" content="短信发送失败，已经扣减的短信数量将会返还，你可以在账户上查看返还的记录" placement="top">
              <i class = "el-icon-warning" style="font-size:16px;"></i>
            </el-tooltip>
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 短信充值 -->
    <el-dialog :visible.sync="topupshow" title="短信充值" width="600px" v-loading="topuploading" custom-class="dialogpad">
      <div>当前账户剩余短信数量（条）：
        <span style="color:#f56c6c">{{agreement.SMSCount}}</span>
      </div>
      <div class = "flexRow" style="flex-wrap:wrap;margin-top:20px">
        <div v-for="(v,i) in smsoptionslist" :key="i" class = "radioborder" @click="choosesmsopt(i)" :class="{selected:v.Id==smsoptions.Id}"
          style="margin:0px 30px 30px 0px">{{v.SMSPackageCount}}条</div>
      </div>
      <div style="text-align:right;margin-top:10px">
        <span style="margin-right:20px;color:#999999">应付： 
          <span style="color:#303133;font-size:18px">￥{{smsoptions.Id?smsoptions.SMSPackageSellPrice:0}}</span>
        </span>
        <el-button type="primary" @click="buy">确认购买</el-button>
      </div>
      <div style="text-align:right;margin-top:16px">
        <el-checkbox v-model="knowrules">
          <span style="color:#606266">阅读并同意 </span>
          <el-link href="https://jusnn6k8al.feishu.cn/docs/doccnwVl9r0yFoN2k3n4bo6ExNX" target="_blank"
           :underline="false" type="primary">{{agreement.AgreementTitle}}</el-link>
        </el-checkbox>
      </div>
    </el-dialog>

    <!-- 支付 -->
    <el-dialog :visible.sync="payshow" v-loading="payloading" title="提示" width="400px">
			<div>使用支付宝扫描下方二维码，完成支付。二维码30分钟更新一次</div>
			<div class = "flexRow" style="justify-content:center;">
				<vue-qr :text="paymsg.QrcodeUrl" :size="300"  ref="Qrcode"></vue-qr>
			</div>
			<div style="text-align:center">
				<el-button type="text" @click="againgetpay">
					<i class = "el-icon-refresh-right"></i>
					重新获取二维码</el-button>
			</div>
			<div style="text-align:right;margin-top:20px">
				<el-button @click="payerr">支付失败</el-button>
				<el-button type="primary" @click="paysuc">我已支付成功</el-button>
			</div>
		</el-dialog>
  </div>
</template>

<script>
import {
  mallsmsmallsmsinfo,
  mallsmsmallsmsquantityrecordlist,
  mallsmsmallsmsquantityrecorddetail,
  mallsmssystemsmspackageconfiglist,
  mallsmsapplypaysmspackage,
  mallsmsbuysmspackageResult,
  mallNoticeSubscribeConfig
} from "@/api/api"
import vueQr from 'vue-qr';
export default {
  components: {
    vueQr
  },
  data () {
    return {
      smsnum:0,
      agreement:{},
      numloading:false,
      contactshow:false,
      reason:null,
      reasonlist:[
        {value:null,label:'全部'},
        {value:3,label:'短信充值'},
        {value:1,label:'短信发送'},
        {value:2,label:'余额返还'},
        {value:5,label:'外呼提醒'},
      ],
      starttime:'',
      endtime:'',
      tablelist:[],
      tableloading:false,
      currentPage:1,
      sizepage:20,
      total:null,

      serviceshow:false,
      servicefont:'充值过程中，若遇到疑问，使用微信扫描下方二维码，',

      detailshow:false,
      detailmsg:{},

      topupshow:false,
      smsoptions:{},
      smsoptionslist:[],
      knowrules:false,
      topuploading:false,

      payshow:false,
      payloading:false,
      paymsg:{},
      message:{},
    }
  },
  computed: {
    thenum(){
      let num = 1
      let length = 0
      if(this.detailmsg.Content){
        if(this.detailmsg.Content.length<=70){
          num = 1
          length = 0
        }else{
          // console.log(e)
          num = Math.ceil(this.detailmsg.Content.length/67)
          length = this.detailmsg.Content.length-70
        }
      }
      return {num,length}
    },
  },
  created () {
    this.getsmsnum()
    this.gettablelist()
    this.getMessageList()
  },
  methods: {
    handclick(){
      this.$router.push({
        path: '/setting/Newssubscription',
        // query:{
        //    parameter:this.message
        // }
      })
    },
    //获取消息订阅信息
			async getMessageList(){
          try{
					let result_remind=await mallNoticeSubscribeConfig()
          console.log(result_remind)
					this.message=result_remind.Result.find((i)=>{ return i.Type==2})
          
				}
				finally{}
			},
    async getpayresult(){
      this.payloading = true
      try{
        let data = {
          PayOrderNo:this.paymsg.PayOrderNo
        }
        let res = await mallsmsbuysmspackageResult(data)
        // console.log(res)
        if(res.Result.IsPay){
          this.$message.success('充值成功')
          this.payshow = false
          this.topupshow = false
          this.getsmsnum()
          this.gettablelist()
        }else {
          this.serviceshow = true
          this.getrulesFont = '支付结果获取中，若遇到问题，使用微信扫描下方二维码'
        }
      }finally{
        this.payloading = false
      }
    },
    payerr(){
      this.serviceshow=true,
      this.servicefont='支付过程中，若遇到问题，使用微信扫描下方二维码'
    },
    paysuc() {
      this.getpayresult()
    },
    againgetpay(){
      this.applybuy('payloading')
    },
    buy(){
      if(this.knowrules){
        this.applybuy('topuploading')
      }else{
        this.$message.error(`请确认您已知晓并同意${this.agreement.AgreementTitle}`)
      }
    },
    async applybuy(loadingkey){
      this[loadingkey] = true
      try{
        let data = {
          Id:this.smsoptions.Id
        }
        let res = await mallsmsapplypaysmspackage(data)
        if(res.IsSuccess){
          // console.log(res)
          this.payshow = true
          this.paymsg = res.Result
        }
      }finally{
        this[loadingkey] = false
      }
    },
    choosesmsopt(e){
      // if(this.smsoptions.Id == this.smsoptionslist[e].Id){
      //   this.smsoptions = {} 
      // }else{
        this.smsoptions = this.smsoptionslist[e]
      // }
    },
    async topup(){
      try{
        this.numloading = true
        let res = await mallsmssystemsmspackageconfiglist()
        if(res.IsSuccess){
          // console.log(res)
          this.smsoptionslist = res.Result.sort((a,b)=>{
            return a.SMSPackageCount-b.SMSPackageCount
          })
          this.smsoptions = this.smsoptionslist[0]
          this.topupshow = true
        }
      }finally{
        this.numloading = false
      }
    },
    getdetail(e){
      this.togetdetail(e)
    },
    async togetdetail(e){
      try{
        this.tableloading = true
        let msg = await e
        let data = {
          Id:msg.Id
        }
        let res = await mallsmsmallsmsquantityrecorddetail(data)
        if(res.IsSuccess){
          // console.log(res)
          this.detailmsg = res.Result
          this.detailshow = true
        }
      }finally{
        this.tableloading = false
      }
    },
    handleSizeChange(e){
      this.currentPage = 1
      this.sizepage = e
      this.gettablelist()
    },
    handleCurrentChange(e){
      this.currentPage = e
      this.gettablelist()
    },
    query(){
      this.currentPage = 1
      this.gettablelist()
    },
    async gettablelist(){
      this.tableloading = true
      try{
        let data = {
          RecordType:this.reason,
          StartTime:this.starttime,
          EndTime:this.endtime,
          Skip:(this.currentPage-1)*this.sizepage,
          Take:this.sizepage
        }
        let res = await mallsmsmallsmsquantityrecordlist(data)
        if(res.IsSuccess){
          this.tablelist = res.Result.Results
          this.total = res.Result.Total
        }
      }finally{
        this.tableloading = false
      }
    },

    async getsmsnum(){
      this.numloading = true
      try{
        let res = await mallsmsmallsmsinfo()
        // console.log(res)
        if(res.IsSuccess){
          this.smsnum = res.Result.SMSCount
          this.agreement = res.Result
        }
      }finally{
        this.numloading = false
      }
    },
  }
}
</script>

<style lang = "less" scoped>
.remind {
		width: 742px;
        height: 40px;
        background: #FBFDFF;
        border-radius: 2px 2px 2px 2px;
        opacity: 1;
        border: 1px solid #409EEF;
		    font-size: 14px;
		    color: #606266  ;
		    line-height: 40px;
		    margin-bottom: 10px;
	}
.co{
	color:rgb(145, 204, 253);
	cursor: pointer;
}
.flexRow{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.smsnumfont{
  font-size: 20px;
  color: #303133;
  line-height: 1.5;
}
.keyfont{
  font-size: 14px;
  color: #606266;
  white-space: nowrap;
  line-height: 1.5;
  font-weight: bold;
}
.detailtitle{
  font-size: 14px;
  color: #606266;
  margin-top: 10px;
  span{
    font-size: 12px;
    color: #999999;
    margin-left:15px
  }
}
.graybox{
  background: #F5F5F5;
  padding:15px;
  font-size: 14px;
  color: #606266;
  margin:20px 0px;
  line-height: 1.5;
  /* border:1px solid black; */
  max-height: 250px;
  overflow: hidden;
  overflow-y: auto;
}
.flexCol{
  display:flex;
  flex-direction: column;
  margin-right: 50px;
  .numfont{
    font-size: 20px;
    font-weight: bold;
    color: #303133;
  }
}

.radioborder {
  white-space: nowrap;
  width: 150px;
  height: 75px;
  /* padding: 15px 25px; */
  font-size: 18px;
  background: #FFF;
  border: 1px solid #DCDFE6;
  border-radius: 4px;
  font-weight: 500;
  color: #606266;
  text-align: center;
  outline: 0;
  cursor: pointer;
  -webkit-transition: all .3s cubic-bezier(.645,.045,.355,1);
  transition: all .3s cubic-bezier(.645,.045,.355,1);
  display: flex;
  align-items: center;
  justify-content: center;
}
.selected{
  color: white;
  background: #409eff;
}
.pay-help{
		font-size: 12px;
		display: inline-block;
		margin-left: 20px;
		cursor: pointer;
		color: #666;
		
		span{
			display: inline-block;
			vertical-align: 1px;
			margin-left: 3px;
			border-bottom: 1px solid #666;
		}
	}
</style>
<style>
.dialogpad .el-dialog__body{
      padding: 10px 20px 20px 20px;
}
</style>